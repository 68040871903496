import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $insertNodes } from "lexical";
import { FC, useEffect } from "react";
import { $generateNodesFromDOM } from "@lexical/html";

const ImportHTMLPlugin: FC<{ initialHTML: string }> = ({ initialHTML }) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (editor) {
      editor.update(() => {
        $getRoot().clear();
        if (initialHTML) {
          const parser = new DOMParser();
          const dom = parser.parseFromString(initialHTML, "text/html");

          const nodes: any = $generateNodesFromDOM(editor, dom);

          $insertNodes(nodes);
        }
      });
    }
  }, [editor, initialHTML]);

  return null;
};

export default ImportHTMLPlugin;
