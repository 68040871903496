export interface preferenceType {
  themeMode: themeModeEnum;
  expandMenu: boolean;
  eventDetailMenu: boolean;
}

export enum themeModeEnum {
  light = "light",
  system = "system",
  dark = "dark",
}

export type selectedMenuType = "users" | "locations";
