import io, { Socket } from "socket.io-client";

const SOCKET_SERVER_URL = window.location.origin;

class SocketService {
  private socket: Socket;

  constructor() {
    this.socket = io(SOCKET_SERVER_URL, { autoConnect: false });
  }

  connect(): void {
    this.socket.connect();
    // You can define additional event listeners here
    this.socket.on("connect", () => {
      console.log("Connected to WebSocket server");
      this.socket.emit("login");
    });
  }

  disconnect(): void {
    if (this.socket.connected) {
      this.socket.disconnect();
    }
  }

  subscribeToEvent(eventName: string, callback: (data: any) => void): void {
    this.socket.on(eventName, callback);
  }

  unsubscribeFromEvent(eventName: string, callback: (data: any) => void): void {
    this.socket.off(eventName, callback);
  }
}

const socketService = new SocketService();

export default socketService;
