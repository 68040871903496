import { createTheme } from "@mui/material";

export const gozuprodDarkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#ff685a",
      main: "#df5151",
      dark: "#a23b39",
    },
    secondary: {
      light: "#027a77",
      main: "#047b79",
      dark: "#025554",
    },
    background: {
      default: "#0d0d0d",
      paper: "#171717",
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
  },
  typography: {
    fontFamily: "Gotham",
    fontWeightMedium: 300,
    fontWeightRegular: 200,
    h2: {
      fontSize: "2.5rem",
    },
  },
});

export const gozuprodLightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#ff685a",
      main: "#df5151",
      dark: "#a23b39",
    },
    secondary: {
      light: "#027a77",
      main: "#047b79",
      dark: "#025554",
    },
    background: {
      default: "#fbfbfbc",
      paper: "#ffffff",
    },
  },

  typography: {
    fontFamily: "Gotham",
    fontWeightMedium: 300,
    fontWeightRegular: 200,
    h2: {
      fontSize: "2.5rem",
    },
  },
});
