import { Autocomplete, Stack, TextField } from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { ChangeEvent, FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import EventContext from "../../../../context/EventsContext";
import LocationContext from "../../../../context/LocationsContext";
import { EventType } from "../../../../shared/types/events";

interface EventDetailGeneralProps {
  error: boolean;
  setSelectedMenu(value: number): void;
}

const EventDetailGeneral: FunctionComponent<EventDetailGeneralProps> = ({ error }) => {
  const { selectedEvent: selectedEventValue, setSelectedEvent } = useContext(EventContext);

  // remove the null type because cannot be possible
  const selectedEvent = selectedEventValue as EventType;

  const [eventName, setEventName] = useState<string>("");

  const [eventLocation, setEventLocation] = useState<string>("");

  const { locationsList } = useContext(LocationContext);

  const locationsOption = useMemo(() => {
    return locationsList.map((v) => {
      return { label: v.name, _id: v._id };
    });
  }, [locationsList]);

  useEffect(() => {
    setEventName(selectedEvent.name);
  }, [selectedEvent.name]);

  useEffect(() => {
    setEventLocation(selectedEvent.locationName);
  }, [selectedEvent.locationName]);

  return (
    selectedEvent && (
      <Stack spacing={2}>
        <TextField
          label={"Nom"}
          error={error && selectedEvent.name === ""}
          value={eventName}
          onBlur={() => {
            setSelectedEvent((prevState) => {
              if (prevState) {
                return { ...prevState, name: eventName };
              } else {
                return null;
              }
            });
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEventName(e.currentTarget.value);
          }}
        />
        <Stack direction={"row"} spacing={2}>
          <DatePicker
            sx={{ flexGrow: 1 }}
            label={"Date début"}
            value={dayjs(selectedEvent.startDate)}
            onChange={(newValue) => {
              if (newValue) {
                const selected = new Date(newValue.valueOf());
                const newStartDate = new Date(selectedEvent.startDate.valueOf());
                newStartDate.setFullYear(
                  selected.getFullYear(),
                  selected.getMonth(),
                  selected.getDate()
                );
                const newEndDate = new Date(selectedEvent.endDate.valueOf());
                newEndDate.setFullYear(
                  selected.getFullYear(),
                  selected.getMonth(),
                  selected.getDate()
                );
                setSelectedEvent({
                  ...selectedEvent,
                  startDate: newStartDate,
                  endDate: newEndDate,
                });
              }
            }}
          />
          <TimePicker
            sx={{ flexGrow: 1 }}
            label={"Heure début"}
            value={dayjs(selectedEvent.startDate)}
            ampm={false}
            onChange={(newValue) => {
              if (newValue) {
                const selected = new Date(newValue.valueOf());
                const newStartDate = new Date(selectedEvent.startDate.valueOf());
                newStartDate.setHours(selected.getHours(), selected.getMinutes(), 0, 0);
                setSelectedEvent({
                  ...selectedEvent,
                  startDate: newStartDate,
                });
              }
            }}
          />
        </Stack>
        <Stack direction={"row"} spacing={2}>
          <DatePicker
            sx={{ flexGrow: 1 }}
            label={"Date fin"}
            value={dayjs(selectedEvent.endDate)}
            onChange={(newValue) => {
              if (newValue) {
                const selected = new Date(newValue.valueOf());
                const newEndDate = new Date(selectedEvent.endDate.valueOf());
                newEndDate.setFullYear(
                  selected.getFullYear(),
                  selected.getMonth(),
                  selected.getDate()
                );
                setSelectedEvent({
                  ...selectedEvent,
                  endDate: newEndDate,
                });
              }
            }}
          />
          <TimePicker
            label={"Heure fin"}
            value={dayjs(selectedEvent.endDate)}
            onChange={(newValue) => {
              if (newValue) {
                const selected = new Date(newValue.valueOf());
                const newStartDate = new Date(selectedEvent.endDate.valueOf());
                newStartDate.setHours(selected.getHours(), selected.getMinutes(), 0, 0);
                setSelectedEvent({
                  ...selectedEvent,
                  endDate: newStartDate,
                });
              }
            }}
            sx={{ flexGrow: 1 }}
          />
        </Stack>
        <Autocomplete
          options={locationsOption}
          freeSolo
          autoSelect
          onBlur={() => {
            const location = locationsList.find((e) => e.name === eventLocation);
            if (location) {
              setSelectedEvent({
                ...selectedEvent,
                locationName: eventLocation,
                locationId: location._id,
                locationInfo: { ...location },
              });
            } else {
              setSelectedEvent({
                ...selectedEvent,
                locationId: undefined,
                locationName: eventLocation,
              });
            }
          }}
          onInputChange={(e, newVal) => {
            setEventLocation(newVal);
          }}
          value={selectedEvent.locationName}
          renderInput={(params) => (
            <TextField
              {...params}
              error={error && selectedEvent.locationName === ""}
              label={"Lieu"}
            ></TextField>
          )}
        ></Autocomplete>
      </Stack>
    )
  );
};

export default EventDetailGeneral;
