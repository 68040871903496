import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { FunctionComponent, ReactNode, useContext } from "react";
import { gozuprodDarkTheme, gozuprodLightTheme } from "../../shared/themes";
import PreferenceContext from "../../context/PreferenceContext";
import { themeModeEnum } from "../../shared/types/preference";

interface ThemeInjectorProps {
  children?: ReactNode;
}

const ThemeInjector: FunctionComponent<ThemeInjectorProps> = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { userPreference } = useContext(PreferenceContext);

  return (
    <ThemeProvider
      theme={
        userPreference.themeMode === themeModeEnum.light
          ? gozuprodLightTheme
          : userPreference.themeMode === themeModeEnum.dark
          ? gozuprodDarkTheme
          : prefersDarkMode
          ? gozuprodDarkTheme
          : gozuprodLightTheme
      }
    >
      <CssBaseline>{children}</CssBaseline>
    </ThemeProvider>
  );
};

export default ThemeInjector;
