import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@mui/icons-material";

export const eventTypes = {
  paragraph: "paragraph",
  h1: "h1",
  h2: "h2",
  ul: "ul",
  ol: "ol",
  quote: "quote",
  formatCode: "formatCode",
  formatUndo: "formatUndo",
  formatRedo: "formatRedo",
  formatBold: "formatBold",
  formatItalic: "formatItalic",
  formatUnderline: "formatUnderline",
  formatStrike: "formatStrike",
  formatInsertLink: "formatInsertLink",
  formatAlignLeft: "formatAlignLeft",
  formatAlignCenter: "formatAlignCenter",
  formatAlignRight: "formatAlignRight",
  insertImage: "insertImage",
};

const pluginList = [
  {
    id: 1,
    icon: FormatBold,
    event: eventTypes.formatBold,
  },
  {
    id: 2,
    icon: FormatItalic,
    event: eventTypes.formatItalic,
  },
  {
    id: 3,
    icon: FormatUnderlined,
    event: eventTypes.formatUnderline,
  },
];

export default pluginList;
