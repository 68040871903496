import { createContext, useState, ReactNode } from "react";
import React from "react";
import { EventLocation } from "../shared/types/events";
import useRequest from "../hook/useRequest";

interface LocationContextType {
  locationsList: EventLocation[];
  setLocationsList(value: EventLocation[]): void;
  loadLocationsListDB(): void;
}

const LocationContext = createContext<LocationContextType>(null!);

interface EventContextProviderProps {
  children: ReactNode;
}

export const LocationContextProvider: React.FC<EventContextProviderProps> = ({
  children,
}) => {
  const [locationsList, setLocationsList] = useState<EventLocation[]>([]);

  const { getRequest } = useRequest("locations");

  const loadLocationsListDB = async () => {
    const r = await getRequest({});
    if (r) {
      setLocationsList(r);
    }
  };

  return (
    <LocationContext.Provider
      value={{
        locationsList,
        setLocationsList,
        loadLocationsListDB,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export default LocationContext;
