import WorkspaceBase from "../../components/base/WorkspaceBase/WorkspaceBase";
import DashboardBase from "../../components/base/DashboardBase/DashboardBase";

const CalendarLayout = () => {
  return (
    <DashboardBase>
      <WorkspaceBase></WorkspaceBase>
    </DashboardBase>
  );
};

export default CalendarLayout;
