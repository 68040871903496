import { useEffect, useState } from "react";
import { User } from "../shared/types/users";
function UseJobOptions(userList: User[]) {
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    const newOptions: string[] = [];

    userList.forEach((e) => {
      e.jobs.forEach((j) => {
        if (!newOptions.includes(j)) {
          newOptions.push(j);
        }
      });
    });
    setOptions(newOptions);
  }, [userList]);

  return options;
}

export default UseJobOptions;
