import { ButtonHTMLAttributes, FunctionComponent } from "react";
import "./GoogleButtton.css";

const GoogleButton: FunctionComponent<
  ButtonHTMLAttributes<HTMLButtonElement>
> = (props) => {
  return <button {...props} className="login-with-google-btn"></button>;
};

export default GoogleButton;
