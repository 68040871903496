import axios, { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { fr } from "../translation/translation";

function useRequest(type: string) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const axiosService = axios.create({
    withCredentials: true,
    baseURL: window.location.origin,
  });

  axiosService.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 401) {
        navigate("/login");
      } else {
        return Promise.reject(error);
      }
    }
  );

  const deleteRequest = async (id: string | undefined) => {
    const r = await axiosService.delete(`api/${type}/${id}`);
    if (r.status === 200) {
      enqueueSnackbar(`${fr[type]} supprimé avec succès !`, {
        variant: "success",
      });
      return r.data;
    } else {
      enqueueSnackbar("Erreur serveur, utilisateur non supprimé", {
        variant: "error",
      });
      return false;
    }
  };

  const updateRequest = async (id: string | undefined, data: any) => {
    const r = await axiosService.patch(`api/${type}/${id}`, data);
    if (r.status === 200) {
      enqueueSnackbar(`${fr[type]} modifié avec succès !`, {
        variant: "success",
      });
      return r.data;
    } else {
      enqueueSnackbar("Erreur serveur, utilisateur non modifié", {
        variant: "error",
      });
      return false;
    }
  };

  const createRequest = async (body: any) => {
    const response: any = await axiosService.post(`api/${type}`, body).catch((e) => {
      return false;
    });
    if (response && response.status === 201) {
      enqueueSnackbar(`${fr[type]} crée avec succès !`, {
        variant: "success",
      });
      return response.data;
    } else {
      enqueueSnackbar(`Erreur serveur : ${fr[type]} non crée`, {
        variant: "error",
      });
      return false;
    }
  };

  function objectToQueryString(params: any) {
    return Object.keys(params)
      .filter((k) => params[k] !== undefined)
      .map((key) => {
        if (Array.isArray(params[key])) {
          return params[key]
            .map((value: string) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            .join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
      })
      .join("&");
  }

  const getRequest = async (options: {
    startDate?: any;
    endDate?: any;
    user?: any;
    location?: any;
  }) => {
    const params = objectToQueryString(options);
    const r = await axiosService.get(`/api/${type}/?${params}`);
    if (r.status === 200) {
      return r.data;
    } else {
      enqueueSnackbar(`Erreur serveur : ${fr[type]} non récupérés`, {
        variant: "error",
      });

      return false;
    }
  };

  const customGetRequest = async (uri: string) => {
    const r = await axiosService.get(uri);
    if (r.status === 200) {
      return r.data;
    } else {
      return false;
    }
  };

  return {
    createRequest,
    getRequest,
    deleteRequest,
    updateRequest,
    customGetRequest,
  };
}

export default useRequest;
