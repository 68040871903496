import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FunctionComponent, useContext } from "react";
import UserContext from "../../../context/UsersContext";
import useRequest from "../../../hook/useRequest";

interface ConfirmDialogProps {
  selectedUser: number;
  setOpen(value: boolean): void;
  open: boolean;
}

const DeleteDialog: FunctionComponent<ConfirmDialogProps> = (props) => {
  const { selectedUser, setOpen, open } = { ...props };

  const { usersList, setUsersList } = useContext(UserContext);

  const { deleteRequest } = useRequest("users");

  async function handleDelete() {
    const r: any = await deleteRequest(usersList[selectedUser]._id);
    if (r) {
      setUsersList(usersList.filter((v, i) => i !== selectedUser));
    }
    handleClose();
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Êtes-vous sûr de supprimer {usersList[selectedUser]?.username} ?</DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button color="error" onClick={handleDelete}>
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
