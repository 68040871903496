import { FunctionComponent, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import HeaderBar from "../components/common/HeaderBar/HeaderBar";
import UserContext from "../context/UsersContext";
import socketService from "../services/socketService";
const Dashboard: FunctionComponent = () => {
  const { loadUserDB } = useContext(UserContext);

  useEffect(() => {
    loadUserDB();
    socketService.connect();
  }, []);

  return (
    <>
      <HeaderBar />
      <Outlet />
    </>
  );
};

export default Dashboard;
