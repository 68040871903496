import {
  CircularProgress,
  Divider,
  Hidden,
  Stack,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import EventDetail from "./EventDetail/EventDetail";
import EventsList from "./EventList/EventsList";
import EventContext from "../../context/EventsContext";
import DashboardBase from "../../components/base/DashboardBase/DashboardBase";
import UserContext from "../../context/UsersContext";
import LocationContext from "../../context/LocationsContext";
import EventTopMenu from "./EventTopMenu/EventTopMenu";
import { getIncomingDate, getPastDate } from "../../shared/function";
import WorkspaceBase from "../../components/base/WorkspaceBase/WorkspaceBase";
import EventDetailDrawer from "./EventDetailDrawer/EventDetailDrawer";

const EventLayout = () => {
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const { eventList } = useContext(EventContext);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const { loadUserListDB } = useContext(UserContext);
  const { eventFilter, updateEventList, setEventList } =
    useContext(EventContext);
  const { loadLocationsListDB } = useContext(LocationContext);

  const [selectedFilter, setSelectedFilter] = useState(0);

  const theme = useTheme();

  function handleInComing() {
    setSelectedFilter(0);
    setEventList(null);
    updateEventList({
      filter: {
        ...eventFilter,
        ...getIncomingDate(),
        isDeleted: false,
        page: 1,
      },
    });
  }

  function handleArchive() {
    setSelectedFilter(1);
    setEventList(null);
    updateEventList({
      filter: { ...eventFilter, ...getPastDate(), isDeleted: false, page: 1 },
    });
  }

  function handleDeleted() {
    setSelectedFilter(2);
    setEventList(null);
    updateEventList({
      filter: {
        ...eventFilter,
        startDate: undefined,
        endDate: undefined,
        isDeleted: true,
        sort: "startDate_asc",
        page: 1,
      },
    });
  }

  useEffect(() => {
    loadUserListDB();
    loadLocationsListDB();
    updateEventList();
  }, []);

  return (
    <DashboardBase>
      <Stack>
        <Tabs value={selectedFilter}>
          <Tab
            sx={{ width: "10vw", whiteSpace: "nowrap" }}
            onClick={handleInComing}
            label={"A venir"}
          />
          <Tab
            sx={{ width: "10vw", whiteSpace: "nowrap" }}
            onClick={handleArchive}
            label={"Archives"}
          />
          <Tab
            sx={{ width: "10vw", whiteSpace: "nowrap" }}
            onClick={handleDeleted}
            label={"Corbeille"}
          />
        </Tabs>
      </Stack>
      <WorkspaceBase>
        <Stack
          direction={"column"}
          sx={{
            width: "65%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <EventTopMenu
            setIsAddMode={setIsAddMode}
            setOpenDetail={setOpenDetail}
          />
          {eventList === null ? (
            <Stack
              flexGrow={1}
              m={3}
              spacing={3}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress />
            </Stack>
          ) : (
            <EventsList
              setIsAddMode={setIsAddMode}
              setOpenDetail={setOpenDetail}
              openDetail={openDetail}
            />
          )}
        </Stack>
        <Divider orientation="vertical" />
        <Hidden mdDown>
          <Stack
            style={{
              width: "35%",
            }}
          >
            <EventDetail
              isAddMode={isAddMode}
              setIsAddMode={setIsAddMode}
              setOpenDetail={setOpenDetail}
            />
          </Stack>
        </Hidden>
        <Hidden mdUp>
          <EventDetailDrawer isAddMode={isAddMode} setIAddMode={setIsAddMode} />
        </Hidden>
      </WorkspaceBase>
    </DashboardBase>
  );
};

export default EventLayout;
