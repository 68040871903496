import { Stack } from "@mui/material";
import "dayjs/locale/fr";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import EventContext from "../../../../context/EventsContext";
import TextEditor from "../../../../components/TextEditor/TextEditor";
import { EventType } from "../../../../shared/types/events";
import usePrevious from "../../../../hook/usePrevious";

interface EventDetailGeneralProps {}

const EventDetailSummary: FunctionComponent<EventDetailGeneralProps> = () => {
  const { selectedEvent: selectedEventValue, setSelectedEvent } =
    useContext(EventContext);
  const selectedEvent = selectedEventValue as EventType;

  const prevEvent = usePrevious(selectedEvent._id);

  const [initialValue, setInitialValue] = useState<string>(
    selectedEvent.summary
  );

  useEffect(() => {
    if (prevEvent !== selectedEvent._id) {
      setInitialValue(selectedEvent.summary);
    }
  }, [selectedEvent, prevEvent]);

  function handleChange(v: string) {
    setSelectedEvent({ ...selectedEvent, summary: v });
  }

  return (
    <Stack spacing={3} flexGrow={1} overflow={"auto"}>
      <TextEditor
        initialValue={initialValue}
        onChangeCompleted={handleChange}
      />
    </Stack>
  );
};

export default EventDetailSummary;
