import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { FunctionComponent, useContext } from "react";
import { Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { EventLocation } from "../../../shared/types/events";
import LocationContext from "../../../context/LocationsContext";
interface UsersGridProps {
  setSelectedLocation(value: EventLocation): void;
  setOpenEditDialog(value: boolean): void;
  setOpenDeleteDialog(value: boolean): void;
}

const LocationGrid: FunctionComponent<UsersGridProps> = (props) => {
  const {
    setSelectedLocation,
    setOpenEditDialog: setOpenDialog,
    setOpenDeleteDialog: setDeleteDialog,
  } = {
    ...props,
  };

  const { locationsList } = useContext(LocationContext);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Adresse",
      flex: 1,
    },
    {
      field: "color",
      headerName: "Couleur",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography bgcolor={params.row.color} p={1} borderRadius={2}>
            {params.row.color}
          </Typography>
        );
      },
    },
    {
      field: "googleCalendarId",
      headerName: "Google calendar id",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              setSelectedLocation({ ...params.row });
              setOpenDialog(true);
            }}
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            onClick={() => {
              setSelectedLocation({ ...params.row });
              setDeleteDialog(true);
            }}
            icon={<Delete />}
            label="Delete"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DataGrid
      loading={locationsList ? false : true}
      hideFooter
      columns={columns}
      rows={locationsList.map((u, i) => {
        return { ...u, id: i };
      })}
    />
  );
};

export default LocationGrid;
