import { createContext, useState, ReactNode, useEffect } from "react";
import React from "react";
import { preferenceType } from "../shared/types/preference";
import { defaultPreference, defaultUser } from "../shared/default";

interface PreferenceContextType {
  userPreference: preferenceType;
  setUserPreference(value: preferenceType): void;
}

const PreferenceContext = createContext<PreferenceContextType>(null!);

interface PreferenceContextProviderProps {
  children: ReactNode;
}

export const PreferenceContextProvider: React.FC<PreferenceContextProviderProps> = ({
  children,
}) => {
  const [userPreference, setUserPref] = useState<preferenceType>(defaultPreference);

  useEffect(() => {
    const item = localStorage.getItem("gozuprod");
    if (item) {
      const preferenceObject = JSON.parse(item);
      setUserPreference(preferenceObject);
    }
  }, []);

  function setUserPreference(prefObject: preferenceType) {
    localStorage.setItem("gozuprod", JSON.stringify(prefObject));
    setUserPref(prefObject);
  }

  return (
    <PreferenceContext.Provider value={{ userPreference, setUserPreference }}>
      {children}
    </PreferenceContext.Provider>
  );
};

export default PreferenceContext;
