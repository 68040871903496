import { getIncomingDate } from "./function";
import { EventType, EventMember, EventFilter } from "./types/events";
import { preferenceType, themeModeEnum } from "./types/preference";
import { User } from "./types/users";

export const defaultUser: User = {
  email: "",
  username: "",
  photo: "",
  roles: [],
  phone: "",
  jobs: [],
  _id: "",
};

export const defaultMember: EventMember = {
  name: "",
  job: "",
  confirmed: false,
  userId: "",
};

export const defaultEvent: EventType = {
  name: "",
  startDate: new Date(new Date().setHours(8, 0, 0, 0)),
  endDate: new Date(new Date().setHours(20, 0, 0, 0)),
  locationName: "",
  projectManager: "",
  summary: "",
  color: "#c2c2c2c2",
  accountingId: 11,
  isDeleted: false,
  teams: [],
};

export const defaultEventFilter: EventFilter = {
  ...getIncomingDate(),
  sort: "startDate_asc",
  user: [],
  location: [],
  name: [],
  isDeleted: false,
  page: 1,
};

export const defaultJobOptions = [
  "Runner Vidéo",
  "Ingé son",
  "Runner Son",
  "Réalisateur",
  "Dir tech",
  "Truquiste",
  "Régisseur",
  "Cadreur",
  "Chef op",
  "Lumière",
];

export const defaultLocationOptions = [
  { label: "Plateau 1", color: "red" },
  { label: "Plateau 2", color: "green" },
  { label: "Plateau 3", color: "blue" },
  { label: "Plateau remote", color: "grey" },
  { label: "Arena", color: "purple" },
];

export const defaultRolesOptions = ["Admin", "Externe"];

export const defaultPreference: preferenceType = {
  themeMode: themeModeEnum.dark,
  expandMenu: true,
  eventDetailMenu: false,
};

export const defaultLocation = {
  name: "",
  address: "",
  color: "",
  googleCalendarId: "",
  _id: "",
};
