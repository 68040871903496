import React, { useContext, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { InputBase, Stack, useTheme } from "@mui/material";
import EventContext from "../../../../context/EventsContext";
import { Close } from "@mui/icons-material";

interface SearchBarProps {}

const SearchBar: React.FC<SearchBarProps> = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { eventFilter, updateEventList } = useContext(EventContext);

  const theme = useTheme();

  const handleSearch = () => {
    if (searchTerm !== "") {
      updateEventList({ filter: { ...eventFilter, name: [searchTerm] } });
    } else {
      clearSearch();
    }
  };

  const clearSearch = () => {
    setSearchTerm("");
    updateEventList({ filter: { ...eventFilter, name: [] } });
  };

  return (
    <Stack
      direction={"row"}
      border={`solid 1px `}
      height={35}
      borderRadius={5}
      sx={{
        [theme.breakpoints.up("md")]: {
          width: "300px",
        },
      }}
    >
      <IconButton size="small" onClick={handleSearch}>
        <SearchIcon />
      </IconButton>
      <InputBase
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch();
          }
        }}
        sx={{ borderWidth: 0 }}
        placeholder="Rechercher..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginRight: "10px", flexGrow: 1 }}
      />
      {eventFilter.name.length > 0 && (
        <IconButton onClick={clearSearch}>
          <Close />
        </IconButton>
      )}
    </Stack>
  );
};

export default SearchBar;
