import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import EventBox from "./EventBox/EventBox";
import EventContext from "../../../context/EventsContext";
import { EventDict, EventType } from "../../../shared/types/events";
import SadIcon from "../../../assets/sad.png";
import { getDateRange } from "../../../shared/function";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

interface EventsLayoutProps {
  setOpenDetail(value: boolean): void;
  openDetail: boolean;
  setIsAddMode(value: boolean): void;
}

const EventsList: FunctionComponent<EventsLayoutProps> = (props) => {
  const {
    selectedEvent,
    setSelectedEvent,
    eventList,
    eventFilter,
    updateEventList,
  } = useContext(EventContext);

  const { openDetail, setIsAddMode } = { ...props };

  const theme = useTheme();

  const [eventDict, setEventDict] = useState<EventDict | null>(null);

  function handlePageChange(page: number) {
    updateEventList({ ...eventFilter, filter: { ...eventFilter, page: page } });
  }

  const createEventDict = (eventList: EventType[]) => {
    //check if option incoming
    let dateList: any = [];
    if (eventList.length > 0) {
      if (eventFilter.startDate) {
        dateList = getDateRange(
          new Date(),
          eventList[eventList.length - 1].endDate
        );
      } else {
        dateList = getDateRange(
          eventList[eventList.length - 1].startDate,
          new Date()
        ).reverse();
      }
    }

    const eventDict = dateList.reduce(
      (eventDict: EventDict, date: string): EventDict => {
        const eventDateList = eventList.filter((e) => {
          const dateRange = getDateRange(
            new Date(e.startDate),
            new Date(e.endDate)
          );
          return dateRange.includes(date);
        });
        if (eventDateList.length > 0) {
          eventDict[date] = eventDateList;
        }
        return eventDict;
      },
      {}
    );
    return eventDict;
  };

  useEffect(() => {
    if (eventList) {
      setEventDict(createEventDict(eventList));
    } else {
      setEventDict(null);
    }
  }, [eventList]);

  return (
    <Stack flexGrow={1} px={2} pb={1} sx={{ overflowY: "auto" }} spacing={1}>
      {eventDict === null ? (
        <Stack
          flexGrow={1}
          m={3}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Stack>
      ) : Object.entries(eventDict).length === 0 ? (
        <Stack
          fontWeight={200}
          flexGrow={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          Pas d'évènement disponible
          <img src={SadIcon} alt="" width={50}></img>
        </Stack>
      ) : (
        <Stack>
          {Object.entries(eventDict).map(
            ([stringDate, eventDateList], index) => {
              const date = new Date(stringDate);
              return (
                <Stack spacing={1} key={index}>
                  <Stack direction={"row"} spacing={2}>
                    <Typography
                      paddingLeft={2}
                      fontWeight={600}
                      fontSize={"1.1rem"}
                      color={theme.palette.grey[500]}
                    >
                      {date
                        .toLocaleDateString(undefined, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          weekday: "long",
                        })
                        .charAt(0)
                        .toUpperCase() +
                        date
                          .toLocaleDateString(undefined, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            weekday: "long",
                          })
                          .slice(1)}
                    </Typography>
                  </Stack>
                  {eventDateList &&
                    eventDateList.map((e, i) => {
                      return (
                        <EventBox
                          key={i}
                          event={e}
                          openDetail={openDetail}
                          selected={
                            selectedEvent ? selectedEvent._id === e._id : false
                          }
                          onClick={() => {
                            setIsAddMode(false);
                            if (selectedEvent?._id === e._id) {
                              setSelectedEvent(null);
                            } else {
                              setSelectedEvent(e);
                              props.setOpenDetail(true);
                            }
                          }}
                        />
                      );
                    })}
                </Stack>
              );
            }
          )}
          <Stack
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            p={1}
          >
            <IconButton
              disabled={eventFilter.page === 1}
              onClick={() => {
                handlePageChange(eventFilter.page - 1);
              }}
            >
              <ArrowBackIos />
            </IconButton>
            <IconButton
              onClick={() => {
                handlePageChange(eventFilter.page + 1);
              }}
            >
              <ArrowForwardIos />
            </IconButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default EventsList;
