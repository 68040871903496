import React, { useEffect, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import { Box, Divider, IconButton, Stack, Tooltip } from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Link,
} from "@mui/icons-material";
import useOnClickListener from "./useOnClickListener";
import pluginList from "./ToolbarIconList";

const ToolbarPlugin: React.FC = () => {
  const { onClick, selectedEventTypes, isLink, editor } = useOnClickListener();

  return (
    <Stack direction={"row"}>
      {pluginList.map((v, i) => {
        return (
          <IconButton
            key={i}
            onClick={() => {
              onClick(v.event);
            }}
            color={selectedEventTypes.includes(v.event) ? "primary" : undefined}
          >
            {<v.icon></v.icon>}
          </IconButton>
        );
      })}
    </Stack>
  );
};

export default ToolbarPlugin;
