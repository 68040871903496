import { AppBar, Button, Hidden, Stack, useMediaQuery, useTheme } from "@mui/material";
import { FunctionComponent, useContext } from "react";
import gozuprodH from "../../../assets/gozuprod_h.png";
import gozuprodV from "../../../assets/gozuprod_v.png";
import UserContext from "../../../context/UsersContext";
import AvatarMenu from "./AvatarMenu/AvatarMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { alphaValue, headerMenuHeight } from "../../../shared/const";
import SearchBar from "./SearchBar/SearchBar";

const HeaderBar: FunctionComponent = () => {
  const { user } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  const theme = useTheme();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        height: headerMenuHeight,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        px: 1,
        columnGap: 5,
      }}
    >
      <Stack flexDirection={"row"} columnGap={4}>
        <img
          onClick={() => {
            navigate("/dashboard/events");
          }}
          style={{ cursor: "pointer", maxHeight: 35, maxWidth: 150 }}
          src={useMediaQuery(theme.breakpoints.down("md")) ? gozuprodV : gozuprodH}
          alt="GOZUPROD"
        ></img>
      </Stack>
      <Hidden smDown>
        <Stack flexGrow={1} flexDirection={"row"}>
          <Button
            onClick={() => {
              navigate("/dashboard/events");
            }}
            sx={{
              transition: "background-color 0.5s",
              bgcolor:
                location.pathname === "/dashboard/events"
                  ? theme.palette.primary.light + alphaValue[20]
                  : "",
              ":hover": {
                bgcolor: theme.palette.primary.light + alphaValue[20],
              },
            }}
          >
            évènements
          </Button>
        </Stack>
      </Hidden>
      <Stack direction={"row"} flexGrow={1}>
        <SearchBar />
      </Stack>
      <Stack direction={"row"} justifyContent={"flex-end"} flexGrow={1}>
        <AvatarMenu photo={user.photo} />
      </Stack>
    </AppBar>
  );
};

export default HeaderBar;
