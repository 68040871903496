import { Paper, PaperProps, useTheme } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";

interface WorkspaceBaseProps extends PaperProps {
  children?: ReactNode;
}

const WorkspaceBase: FunctionComponent<WorkspaceBaseProps> = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        height: "calc(100vh - 110px)",
        display: "flex",
        bgcolor: theme.palette.mode === "light" ? "#ecf1f3" : "#212121",
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default WorkspaceBase;
