import { createContext, useState, ReactNode } from "react";
import React from "react";
import { EventFilter, EventType } from "../shared/types/events";

import { getIncomingDate } from "../shared/function";
import useRequest from "../hook/useRequest";
import { defaultEventFilter } from "../shared/default";

interface UpdateEventOpition {
  filter?: EventFilter;
  skipReload?: boolean;
}

interface EventContextType {
  eventList: EventType[] | null;
  setEventList(value: any | null): void;
  selectedEvent: EventType | null;
  setSelectedEvent: React.Dispatch<React.SetStateAction<EventType | null>>;
  eventFilter: EventFilter;
  setEventFilter(value: EventFilter): void;
  updateEventList(options?: UpdateEventOpition): void;
}

const EventContext = createContext<EventContextType>(null!);

interface EventContextProviderProps {
  children: ReactNode;
}

export const EventContextProvider: React.FC<EventContextProviderProps> = ({
  children,
}) => {
  const [eventList, setEventList] = useState<EventType[] | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<EventType | null>(null);
  const [eventFilter, setEventFilter] =
    useState<EventFilter>(defaultEventFilter);
  const { getRequest } = useRequest("events");

  function updateEventList(options?: UpdateEventOpition) {
    const { skipReload, filter } = { ...options };

    if (!skipReload) {
      setEventList(null);
    }
    if (filter) {
      setEventFilter(filter);
    }

    const queryFilter = filter ? filter : eventFilter;

    getRequest({
      ...queryFilter,
      startDate: queryFilter.startDate && queryFilter.startDate.toISOString(),
      endDate: queryFilter.endDate && queryFilter.endDate.toISOString(),
    }).then((r) => {
      if (r) {
        const formatted = r.map((event: any) => {
          return {
            ...event,
            startDate: new Date(event.startDate),
            endDate: new Date(event.endDate),
          };
        });
        setEventList(formatted);
      }
    });
  }

  return (
    <EventContext.Provider
      value={{
        eventList: eventList,
        setEventList: setEventList,
        selectedEvent: selectedEvent,
        setSelectedEvent: setSelectedEvent,
        eventFilter,
        setEventFilter,
        updateEventList,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};

export default EventContext;
