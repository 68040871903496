import { Button, ButtonProps } from "@mui/material";
import { FunctionComponent, ReactNode } from "react";

interface FilterButtonProps extends ButtonProps {
  isFilled?: boolean;
  children?: ReactNode;
}

const FilterButton: FunctionComponent<FilterButtonProps> = ({ isFilled, children, ...props }) => {
  return (
    <Button
      sx={{
        borderRadius: "20px",
        textTransform: "inherit",
        whiteSpace: "nowrap",
        py: 0,
        px: 2,
        fontWeight: 100,
        minWidth: "auto",
        maxWidth: "auto",
        height: 35,
      }}
      color="secondary"
      variant={isFilled ? "contained" : "outlined"}
      {...props}
    >
      {children}
    </Button>
  );
};
export default FilterButton;
