import { Paper, Stack } from "@mui/material";
import { FunctionComponent } from "react";

import gozuprodV from "../assets/gozuprod_v.png";
import GoogleButton from "../components/googleButton/GoogleButton";

const Login: FunctionComponent = () => {
  return (
    <Stack sx={{ height: "100vh" }} flex={1} justifyContent={"center"} alignItems={"center"}>
      <Paper elevation={8}>
        <Stack spacing={5} justifyContent={"center"} alignItems={"center"} m={3}>
          <img src={gozuprodV} alt="GOZUPROD" width={150}></img>
          <GoogleButton
            onClick={() => {
              window.location.href = window.location.origin + "/auth/google";
            }}
          >
            Sign in with google
          </GoogleButton>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default Login;
