import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import { FunctionComponent, useCallback, useContext } from "react";
import { Avatar, Chip, Stack, Typography } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import UserContext from "../../../context/UsersContext";
interface UsersGridProps {
  setSelectedUser(value: number): void;
  setOpenDialog(value: boolean): void;
  setDeleteDialog(value: boolean): void;
}

const UsersGrid: FunctionComponent<UsersGridProps> = (props) => {
  const { setSelectedUser, setOpenDialog, setDeleteDialog } = {
    ...props,
  };

  const { usersList } = useContext(UserContext);

  const handleEdit = useCallback((row: any) => {
    setSelectedUser(row.id);
    setOpenDialog(true);
  }, []);

  const handleDelete = useCallback((id: number) => {
    setSelectedUser(id);
    setDeleteDialog(true);
  }, []);

  const columns: GridColDef[] = [
    {
      field: "username",
      headerName: "Nom",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            <Avatar src={params.row.photo}></Avatar>
            <Typography>{params.row.username}</Typography>
          </Stack>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "roles",
      headerName: "Roles",
      renderCell: (params) => {
        return params.row.roles.map((v: string, i: number) => {
          return <Chip key={i} color="secondary" label={v} />;
        });
      },
    },
    {
      field: "jobs",
      headerName: "Postes",
      flex: 4,
      renderCell: (params) => {
        return (
          <Stack direction={"row"} overflow={"auto"} spacing={1} p={0.5}>
            {params.row.jobs.map((v: string, i: number) => {
              return <Chip key={i} color="primary" label={v} />;
            })}
          </Stack>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      cellClassName: "actions",
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            onClick={() => {
              handleEdit(params.row);
            }}
            className="textPrimary"
            color="inherit"
          />,
          <GridActionsCellItem
            onClick={() => {
              handleDelete(params.row.id);
            }}
            icon={<Delete />}
            label="Delete"
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <DataGrid
      loading={usersList ? false : true}
      hideFooter
      columns={columns}
      rows={usersList.map((u, i) => {
        return { ...u, id: i };
      })}
    />
  );
};

export default UsersGrid;
