import {
  DarkMode,
  DisplaySettings,
  LightMode,
  Logout,
  Settings,
  SettingsBrightness,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  FormLabel,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { FunctionComponent, useContext, useState } from "react";
import PreferenceContext from "../../../../context/PreferenceContext";
import { themeModeEnum } from "../../../../shared/types/preference";
import { useNavigate } from "react-router-dom";
import useRequest from "../../../../hook/useRequest";

interface AvatarMenuProps {
  photo: string;
}

const AvatarMenu: FunctionComponent<AvatarMenuProps> = ({ photo }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState<boolean>(false);

  const { userPreference, setUserPreference } = useContext(PreferenceContext);

  const { customGetRequest } = useRequest("");

  function handleLogout() {
    customGetRequest("/auth/logout");
    navigate("/login");
  }

  const navigate = useNavigate();

  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(true);
          setAnchorEl(e.currentTarget);
        }}
      >
        <Avatar sx={{ height: 40, width: 40 }} src={photo}></Avatar>
      </IconButton>
      <Menu
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Stack spacing={1}>
          <Stack direction={"row"} px={1} spacing={1}>
            <DisplaySettings color="primary" />
            <Typography color="primary" fontWeight={300}>
              Menu
            </Typography>
          </Stack>
          <Divider />
          <Stack px={2} direction={"column"} spacing={1} sx={{ borderRadius: "25px" }}>
            <FormLabel>Mode</FormLabel>
            <ButtonGroup>
              {(["light", "system", "dark"] as themeModeEnum[]).map((v, i) => {
                return (
                  <Button
                    key={i}
                    variant={v === userPreference.themeMode ? "contained" : "outlined"}
                    onClick={() => {
                      setUserPreference({ ...userPreference, themeMode: v });
                    }}
                  >
                    {v === themeModeEnum.light ? (
                      <LightMode />
                    ) : v === themeModeEnum.system ? (
                      <SettingsBrightness />
                    ) : (
                      <DarkMode />
                    )}
                  </Button>
                );
              })}
            </ButtonGroup>
          </Stack>
          <MenuItem
            onClick={() => {
              navigate("/dashboard/settings");
              setOpen(false);
            }}
          >
            <ListItemIcon>
              <Settings />
            </ListItemIcon>
            <ListItemText color="primary">Paramètres</ListItemText>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText color="primary">Déconnexion</ListItemText>
          </MenuItem>
        </Stack>
      </Menu>
    </>
  );
};

export default AvatarMenu;
