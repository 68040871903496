import { Button, Stack } from "@mui/material";
import UsersGrid from "./UsersGrid";
import { FunctionComponent, useContext, useEffect, useState } from "react";

import UserDialog from "./UserDialog";
import DeleteDialog from "./DeleteDialog";

import UserContext from "../../../context/UsersContext";

interface UsersLayoutProps {}

const UsersSetting: FunctionComponent<UsersLayoutProps> = ({}) => {
  const [selectedUser, setSelectedUser] = useState<number>(0);

  const [openSettingDialog, setSettingDialog] = useState<boolean>(false);

  const [openDeleteDialog, setDeleteDialog] = useState<boolean>(false);

  const { loadUserListDB } = useContext(UserContext);

  function handeOpenAdd() {
    setSelectedUser(-1);
    setSettingDialog(true);
  }

  useEffect(() => {
    loadUserListDB();
  }, []);

  return (
    <Stack flexGrow={1}>
      <Stack flexDirection={"row"} justifyContent={"flex-end"} m={1}>
        <Button variant="contained" color="secondary" onClick={handeOpenAdd}>
          Créer un utilisateur
        </Button>
      </Stack>
      <Stack flexGrow={1} direction={"row"} overflow={"auto"}>
        <UsersGrid
          setDeleteDialog={setDeleteDialog}
          setOpenDialog={setSettingDialog}
          setSelectedUser={setSelectedUser}
        />
        <UserDialog
          selectedUser={selectedUser}
          open={openSettingDialog}
          setOpen={setSettingDialog}
        />
        <DeleteDialog
          selectedUser={selectedUser}
          open={openDeleteDialog}
          setOpen={setDeleteDialog}
        />
      </Stack>
    </Stack>
  );
};

export default UsersSetting;
