import {
  InitialConfigType,
  LexicalComposer,
} from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import ToolbarPlugin from "./Plugin/ToolBarPlugin";
import { Divider, Paper, Typography } from "@mui/material";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListNode } from "@lexical/list";
import theme from "./theme";
import { $generateHtmlFromNodes } from "@lexical/html";
import "./style.css";

import { FunctionComponent, useState } from "react";
import { EditorState, LexicalEditor, TextNode } from "lexical";
import LexicalAutoLinkPlugin from "./Plugin/AutoLinkPlugin";
import ImportHTMLPlugin from "./Plugin/ImportHTMLPlugin";

interface TextEditorProps {
  onChange?(newVal: string): void;
  onChangeCompleted?(newVal: string): void;
  initialValue: string;
}

const TextEditor: FunctionComponent<TextEditorProps> = (props) => {
  const [value, setValue] = useState<string>("");

  function onError(error: any) {
    console.error(error);
  }

  const initialConfig: InitialConfigType = {
    namespace: "MyEditor",
    editable: true,
    theme,
    onError,
    nodes: [AutoLinkNode, LinkNode, TextNode, ListNode],
  };

  function handleChange(editorState: EditorState, editor: LexicalEditor) {
    editor.update(() => {
      const raw = $generateHtmlFromNodes(editor, null);
      if (raw) {
        setValue(raw.toString());
        props.onChange && props.onChange(raw.toString());
      }
    });
  }

  function handleChangeCompleted() {
    props.onChangeCompleted && props.onChangeCompleted(value);
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <Paper
        sx={{
          px: 1,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <ToolbarPlugin />
        <Divider />
        <Typography
          sx={{ position: "relative", flexGrow: 1, p: 1, overflow: "auto" }}
        >
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                onBlur={handleChangeCompleted}
                style={{
                  flexGrow: 1,
                  outline: 0,
                  tabSize: 1,
                  lineHeight: "20px",
                  width: "100%",
                  height: "100%",
                  position: "relative",
                }}
              />
            }
            placeholder={
              <Typography
                sx={{
                  position: "absolute",
                  top: "5px",
                  color: "white",
                  userSelect: "none",
                  pointerEvents: "none",
                  opacity: 0.5,
                }}
              >
                Entrer le brief...
              </Typography>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ImportHTMLPlugin initialHTML={props.initialValue}></ImportHTMLPlugin>
          <OnChangePlugin onChange={handleChange} />
          <HistoryPlugin />
          <LexicalAutoLinkPlugin />
          <AutoFocusPlugin />
          <LinkPlugin />
        </Typography>
      </Paper>
    </LexicalComposer>
  );
};

export default TextEditor;
