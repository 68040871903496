import {
  Autocomplete,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { User } from "../../../shared/types/users";
import { defaultRolesOptions, defaultUser } from "../../../shared/default";

import { useSnackbar } from "notistack";
import UseJobOptions from "../../../hook/useJobOptions";
import UserContext from "../../../context/UsersContext";
import useRequest from "../../../hook/useRequest";

interface UserDialogProps {
  open: boolean;
  setOpen(value: boolean): void;
  selectedUser: number;
}

const UserDialog: FunctionComponent<UserDialogProps> = (props) => {
  const { open, setOpen, selectedUser } = { ...props };

  const [setting, setSetting] = useState<User>(defaultUser);

  const [error, setError] = useState<boolean>(false);

  const { usersList, setUsersList } = useContext(UserContext);

  const jobOptions = UseJobOptions(usersList);

  const { enqueueSnackbar } = useSnackbar();

  const { createRequest, updateRequest } = useRequest("users");

  const isAdd = useMemo(() => {
    return selectedUser === -1;
  }, [selectedUser]);

  function handleClose() {
    setOpen(false);
  }

  function ErrorCheck(setting: User) {
    if (setting.username === "" || setting.email === "" || setting.roles.length === 0) {
      enqueueSnackbar("Champ obligatoire manquant", {
        variant: "error",
      });
      setError(true);
      return true;
    } else {
      return false;
    }
  }

  async function handleAdd() {
    if (ErrorCheck(setting)) {
      return;
    }
    const r: any = await createRequest(setting);
    if (r) {
      setUsersList([...usersList, { ...setting }]);
      handleClose();
    }
  }

  async function handleEdit() {
    if (ErrorCheck(setting)) {
      return;
    }
    const r: any = await updateRequest(setting._id, setting);
    if (r) {
      setUsersList(
        usersList.map((u, i) => {
          if (i === selectedUser) {
            return { ...setting };
          } else {
            return { ...u };
          }
        })
      );
      handleClose();
    }
  }

  useEffect(() => {
    if (usersList[selectedUser]) {
      const user = usersList[selectedUser];
      setSetting({ ...user, jobs: [...user.jobs] });
    }
    if (selectedUser === -1) {
      setSetting({ ...defaultUser });
    }
  }, [usersList, selectedUser, open]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {isAdd
          ? "Ajouter un nouvel utilisateur"
          : "Modifier l'utilisateur " + usersList[selectedUser]?.username}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} m={2}>
          <TextField
            value={setting.username}
            error={error && setting.username === ""}
            onChange={(e) => {
              setSetting({ ...setting, username: e.currentTarget.value });
            }}
            label={"Pseudonyme"}
          />
          <TextField
            value={setting.email}
            error={error && setting.email === ""}
            onChange={(e) => {
              setSetting({ ...setting, email: e.currentTarget.value });
            }}
            label={"Email"}
          />
          <TextField
            value={setting.phone}
            onChange={(e) => {
              setSetting({ ...setting, phone: e.currentTarget.value });
            }}
            label={"Téléphone"}
          />
          <Autocomplete
            options={jobOptions}
            value={setting.jobs}
            multiple
            onChange={(e, newValue) => {
              setSetting({ ...setting, jobs: newValue });
            }}
            freeSolo
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip color="primary" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => <TextField label={"Postes"} {...params}></TextField>}
          ></Autocomplete>
          <Autocomplete
            options={defaultRolesOptions}
            value={setting.roles}
            multiple
            onChange={(e, newValue) => {
              if (newValue) {
                setSetting({ ...setting, roles: newValue });
              }
            }}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip color="primary" label={option} {...getTagProps({ index })} />
              ))
            }
            renderInput={(params) => (
              <TextField
                error={error && setting.roles.length === 0}
                label={"Rôle"}
                {...params}
              ></TextField>
            )}
          ></Autocomplete>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Annuler
        </Button>
        <Button onClick={isAdd ? handleAdd : handleEdit} color="secondary">
          {isAdd ? "Ajouter" : "Modifier"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
