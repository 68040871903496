import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { FunctionComponent, useContext } from "react";

interface ConfirmDialogProps {
  setOpen(value: boolean): void;
  open: boolean;
  onDelete(): Promise<void> | void;
  itemName: string;
}

const DeleteDialog: FunctionComponent<ConfirmDialogProps> = (props) => {
  const { setOpen, open, itemName, onDelete } = { ...props };

  function handleClose() {
    setOpen(false);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Êtes-vous sûr de supprimer {itemName} ?</DialogTitle>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>
          Annuler
        </Button>
        <Button
          color="error"
          onClick={() => {
            onDelete();
            handleClose();
          }}
        >
          Supprimer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
