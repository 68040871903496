import { createContext, useState, ReactNode, useEffect } from "react";
import React from "react";
import { User } from "../shared/types/users";
import { defaultUser } from "../shared/default";
import useRequest from "../hook/useRequest";

interface UserContextType {
  usersList: User[];
  setUsersList(value: User[]): void;
  loadUserListDB(): void;
  user: User;
  setUser(value: any): void;
  loadUserDB(): void;
}

const UserContext = createContext<UserContextType>(null!);

interface UserContextProviderProps {
  children: ReactNode;
}

export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>(defaultUser);
  const [usersList, setUsersList] = useState<User[]>([]);

  const { customGetRequest, getRequest } = useRequest("users");

  const loadUserDB = async () => {
    const r = await customGetRequest("/api/users/current");
    if (r) {
      setUser(r);
    }
  };

  const loadUserListDB = async () => {
    const r = await getRequest({});
    if (r) {
      setUsersList(r);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        loadUserDB,
        usersList,
        setUsersList,
        loadUserListDB,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
